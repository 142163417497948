const title = {
  fontWeight: "700",
  fontSize: "55px",
  lineHeight: "66px",
  fontFamily: "Montserrat, sans-serif",
  color: "#EFCF3C",
};

const campaignHeading = {
  fontWeight: "700",
  fontSize: "84px",
  lineHeight: "76px",
  letterSpacing: "-0.1em",
  color: "#fff",
  WebkitTextStroke: "1.5px #F16122",
};

const campaignHeadingSM = {
  fontSize: "64px",
  lineHeight: "58px",
};

const typographyH1 = {
  fontWeight: "bold",
  fontSize: "44px",
  lineHeight: "73px",
  letterSpacing: "0.04em",
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
};

const typographyMap = {
  fontWeight: "bold",
  fontSize: "14px",
  lineHeight: "30px",
  letterSpacing: "0.02em",
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
};

const typographyH2 = {
  fontWeight: 900,
  fontSize: 30,
  fontFamily:
    "Robo-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serifto",
  lineHeight: "48px",
};

const typographyH2EN = {
  fontWeight: 900,
  fontSize: 18,
  lineHeight: "16px",
  letterSpacing: "0.05em",
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
};

const typographyH3 = {
  fontSize: "24px",
  fontWeight: "bold",
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
  lineHeight: "34px",
};

const typographySubTitleB = {
  fontSize: 24,
  fontWeight: "bold",
  lineHeight: "34px",
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
};
const typographyPrimary = {
  fontSize: 16,
  lineHeight: "25px",
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
};
const typographyPrimaryBold = {
  fontSize: 16,
  lineHeight: "30px",
  fontWeight: "bold",
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
};

const typographySecondary = {
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
  fontSize: 14,
  lineHeight: "22px",
};

const typographySecondaryBold = {
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
  fontSize: 14,
  fontWeight: "bold",
  lineHeight: "18px",
};

const typographyTertiary = {
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
  fontSize: 12,
  lineHeight: "22px",
};

const typographyTertiaryBold = {
  fontSize: 12,
  lineHeight: "22px",
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
  fontWeight: "bold",
};
const typographyTag = {
  fontSize: 11,
  lineHeight: "13px",
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
  fontWeight: "normal",
};
const typographyMapTag = {
  fontSize: 12,
  lineHeight: "30px",
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
  fontWeight: "bold",
};

const typographyMB = {
  fontWeight: 900,
  fontSize: 26,
  lineHeight: "28px",
  letterSpacing: "0.02em",
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
};
const typographyMBH2 = {
  fontWeight: 900,
  fontSize: 22,
  lineHeight: "35px",
  letterSpacing: "0.01em",
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
};

const typographyMedium = {
  fontWeight: "400",
  fontSize: 14,
  lineHeight: "21px",
  fontFamily:
    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
};

const typographyBody1 = {
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "30px",
};

const typographyBody2 = {
  fontWeight: "normal",
  fontSize: 14,
  lineHeight: "22px",
};

const typographyLabel1 = {
  fontWeight: "bold",
  fontSize: 16,
  lineHeight: "25px",
};

const typographyLabel2 = {
  fontWeight: "bold",
  fontSize: 14,
  lineHeight: "18px",
};

const typographyHeadline1 = {
  fontWeight: "bold",
  fontSize: 40,
  lineHeight: "48px",
};
const typographyLabel3 = {
  fontWeight: "bold",
  fontSize: 12,
  lineHeight: "22px",
};

const typographyHeadline2 = {
  fontWeight: "bold",
  fontSize: 30,
  lineHeight: "48px",
};

const typographyHeadline3 = {
  fontWeight: "bold",
  fontSize: 24,
  lineHeight: "34px",
};

const typographyLead1 = {
  fontWeight: "bold",
  fontSize: 16,
  lineHeight: "26px",
};
const typographyHeadline4 = {
  fontWeight: "bold",
  fontSize: 16,
  lineHeight: "22px",
};
const typographyHeading1 = {
  fontWeight: "bold",
  fontSize: 80,
  lineHeight: "80px",
};

const LaptopHeadline2 = {
  fontWeight: "bold",
  fontSize: 36,
  lineHeight: "58px",
};

const LaptopHeadline3 = {
  fontWeight: "700",
  fontSize: 28,
  lineHeight: "45px",
};

export const theme = {
  gray7: "#8C8C8C",
  gray8: "#595959",
  gray9: "#262626",
  primary: "#121212",
  secondary: "#00A0D2",
  orangePrimary: "#F95F00",
  orangeDark: "#F85E00",
  textDark: "#222222",
  grey: "#B6B6B6",
  textPrimary: "#1B1B1B",
  link: "#1890ff",
  signupLink: "#19389C",
  secondaryLight: "#C2C2C2",
  secondaryDark: "#8E8E8E",
  white: "#F5F5F5",
  clearWhite: "#FFFFFF",
  yellow1: "#F7CD48",
  yellow2: "#EAB82D",
  yellow4: "#F0D23C",
  yellow3: "#EFCF3C",
  blue1: "#19389C",
  searchBarBackground: "#FAFAFA",
  blue2: "#1751D5",
  blue4: "#2B478B",
  blue5: "#00a0d2",
  blue6: "#009DD2",
  black: "#1B1B1B",
  accentMid: "#F5FAFF",
  base: "#FFFFFF",
  primaryLine: "#DCDCDC",
  face1: "#FFB27D",
  face2: "#E2915A",
  adminBg: "#001529",
  gray: "#F5F5F5",
  disabled: "#e5e5e5",
  alert: "#EB5757",
  lightBlue: "#B9CBF2",
  orange: "#FA5F00",
  orange1: "#F16122",
  orange2: "#FA5F0070",
  line: "#c4c4c4",
  blackText: "#1B1B1B",
  grayBg: "#F5F5F5",
  textBlue: "#00A0D2",
  yellow: "#F0D23C",
  gray1: "#333333",
  gray5: "#E0E0E0",
  textGray: "#A9A9A9",
  errorBg: "#FFF1EB",
  gray3: "#828282",
  textBlack: "#2E2E2E",
  gray2: "#D9D9D9",
  gray4: "#999",
  background: "#E6E6E6",

  backgroundGrey: "#F4F2EC",
  typography: {
    typographyH1,
    typographyH2,
    typographyH2EN,
    typographyH3,
    typographyPrimary,
    typographyPrimaryBold,
    typographySecondary,
    typographySecondaryBold,
    typographyTertiary,
    typographyTertiaryBold,
    typographySubTitleB,
    typographyMB,
    typographyMBH2,
    typographyTag,
    typographyMapTag,
    typographyMap,
    typographyBody1,
    typographyBody2,
    typographyLabel1,
    typographyLabel2,
    typographyHeadline1,
    typographyHeadline3,
    typographyLead1,
    typographyLabel3,
    typographyHeadline2,
    typographyHeadline4,
    typographyHeading1,
    typographyMedium,
    title,
    campaignHeading,
    campaignHeadingSM,
    LaptopHeadline2,
    LaptopHeadline3,
  },
};
