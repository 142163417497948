import initializeBasicAuth from "nextjs-basic-auth";
const users = [
  {
    user: process.env.NEXT_PUBLIC_BASIC_AUTH_USERNAME,
    password: process.env.NEXT_PUBLIC_BASIC_AUTH_PASSWORD,
  },
];
const basicAuth = initializeBasicAuth({
  users: users,
});

export { basicAuth };
