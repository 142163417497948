import Router from "next/router";
import NProgress from "nprogress";

let timer, state;

const DELAY = 250;

const load = () => {
  if (state === "loading") {
    return;
  }

  state = "loading";

  timer = setTimeout(function () {
    NProgress.start();
  }, DELAY);
};

const stop = () => {
  state = "stop";
  clearTimeout(timer);
  NProgress.done();
};

Router.events.on("routeChangeStart", load);
Router.events.on("routeChangeComplete", stop);
Router.events.on("routeChangeError", stop);

const TopProgressBar: React.FC = () => {
  return null;
};

export { TopProgressBar };
