import { getFirstErrorKey } from "./getFirstErrorKey";

export const focusElement = (errors) => {
  let element = null;
  const firstErrorKey = getFirstErrorKey(errors);
  if (global.window.document.getElementsByName(firstErrorKey).length) {
    element = global.window.document.getElementsByName(firstErrorKey)[0];

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }
};
